import React, { useState } from 'react';

const YoutubeEmbed = ({ embedId, title, video, event }) => (
  
  <div className="h-full relative">
    <video
      className={"w-full "+`${event ? `block` : `hidden`}`}
      height="100%"
      width="100%"
      videoSrcURL={video}
      controls
    >
      <source src={video} type="video/mp4" />
    </video>
    <iframe
      className={"w-full "+`${event ? `hidden` : `block`}`}
      width="853"
      height="320"
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title={title}
    />
  </div>
);

export default YoutubeEmbed;
