import * as React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image';
import YoutubeEmbed from '../components/youtube';

import Icon1 from '../assets/svg/Puenktlich.svg';
import Icon2 from '../assets/svg/AusserhalbOesterreich.svg';
import Icon3 from '../assets/svg/Weitergabe.svg';
import Icon4 from '../assets/svg/Strassen.svg';
import Icon5 from '../assets/svg/Vorkasse.svg';
import Icon6 from '../assets/svg/Mindestalter.svg';
import Icon7 from '../assets/svg/Vollkaskoagb.svg';

import Event1 from '../assets/svg/Ausstattung.svg';
import Event2 from '../assets/svg/TechnischeDaten.svg';
// import Event3 from '../assets/svg/Preis.svg';
import Event4 from '../assets/svg/Inklusivleistungen.svg';

import Seo from '../components/seo';

import Layout from '../components/layout';
import { SRLWrapper } from 'simple-react-lightbox';

export const heroImage = graphql`
  fragment heroImage on File {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
    }
  }
`;
export const galleryThumbnailImage = graphql`
  fragment galleryThumbnailImage on File {
    childImageSharp {
      gatsbyImageData(width: 600, quality: 70, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
    }
  }
`;
export const galleryFullWidhtImage = graphql`
  fragment galleryFullWidhtImage on File {
    childImageSharp {
      gatsbyImageData(width: 1600, quality: 80, formats: [AUTO, WEBP, AVIF])
    }
  }
`;
export const itemImage = graphql`
  fragment itemImage on File {
    childImageSharp {
      gatsbyImageData(width: 1200, formats: [AUTO, WEBP, AVIF])
    }
  }
`;
export const query = graphql`
  query ($slug: String!) {
    itemListDeJson(slug: { eq: $slug }) {
      slug
      name
      brand
      rentwareId
      priceFrom
      priceText
      description
      excerpt
      specs
      buzz
      youtube
      event
      electro
      heroImage {
        ...heroImage
      }
      galleryThumbnailImage: gallery {
        ...galleryThumbnailImage
      }
      galleryFullWidhtImage: gallery {
        ...galleryFullWidhtImage
      }
      highlights
      video
    }
  }
`;

// markup
function ItemPageTemplateDe({ data }) {
  var item = data.itemListDeJson;
  // const heroImage = getImage(item.heroImage)
  // useRentwareItemWidget(item.rentwareId)
  // const itemTypeSlug = slugify(item.brand)

  return (
    <Layout bg="bg-peak">
      <Seo title={item.name + ' mieten'} />
      <div>
        <div className="z-0 relativ bg-peak">
          {/* <GatsbyImage loading="eager" image={heroImage} alt={item.name} className="h-full w-full !absolute py-24 opacity-20 z-[-1]" /> */}
          <div className="inset-0 z-20 py-24 pt-32 mx-auto md:py-48 md:pt-48 boxed md:max-w-screen-xl 2xl:max-w-screen-2xl">
            <h1 className="text-5xl font-arboria md:text-8xl" data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease" data-sal-duration="500">
              {item.name}
            </h1>
            <div className="pt-12 md:flex">
              <h2 className="max-w-2xl text-2xl md:text-4xl" data-sal="slide-up" data-sal-easing="ease" data-sal-delay="400" data-sal-duration="500">
                {item.excerpt}
              </h2>
              <Link to="#booking" className="block mt-12 w-fit md:inset-0 md:m-auto" data-sal="slide-left" data-sal-easing="ease" data-sal-delay="600" data-sal-duration="500">
                <button className="block px-6 py-2 text-white border border-transparent rounded bg-secondary-font hover:bg-transparent hover:border-white">
                  Jetzt buchen{' '}
                  <svg x="0" y="0" viewBox="0 0 960 560" class="w-5 fill-current text-white inline -rotate-90">
                    <path d="M480 344.181L268.869 131.889c-15.756-15.859-41.3-15.859-57.054 0-15.754 15.857-15.754 41.57 0 57.431l237.632 238.937c8.395 8.451 19.562 12.254 30.553 11.698 10.993.556 22.159-3.247 30.555-11.698L748.186 189.32c15.756-15.86 15.756-41.571 0-57.431s-41.299-15.859-57.051 0L480 344.181z"></path>
                  </svg>
                </button>
              </Link>
            </div>
          </div>
        </div>

        <div className="bg-primary-bg">
          {/*############# MOBILE ################*/}

          <div className="py-24 max-w-prose boxed md:hidden">
            <div className="pb-12 max-w-prose" data-sal="slide-top" data-sal-easing="ease" data-sal-delay="200" data-sal-duration="500">
              <h3 className="pb-6 text-3xl font-arboria md:text-6xl">Galerie</h3>
              <SRLWrapper>
                <div className="grid grid-cols-2 gap-4 mb-6 lg:gap-8 md:mb-10 lg:mb-14">
                  {item.galleryThumbnailImage.map((image, index) => {
                    const galleryFullWidhtImage = getSrc(item.galleryFullWidhtImage[index]);
                    const thumbnail = getImage(image);

                    return (
                      <a key={index} href={galleryFullWidhtImage}>
                        <GatsbyImage image={thumbnail} alt="" srl_gallery_image="true" />
                      </a>
                    );
                  })}
                </div>
              </SRLWrapper>
            </div>

            <h3 className="pb-6 text-3xl font-arboria md:text-6xl" data-sal="slide-top" data-sal-easing="ease" data-sal-delay="200" data-sal-duration="500">
              Highlights
            </h3>
            <ul className="max-w-sm">
              {item.highlights.map((item, index) => {
                return (
                  <li
                    key={`content-${index}`}
                    className="flex mb-3 !text-lg font-arboria"
                    data-sal="slide-left"
                    data-sal-easing="ease"
                    data-sal-delay={`${index + 1}00`}
                    data-sal-duration="500"
                  >
                    {item}
                  </li>
                );
              })}
            </ul>
          </div>

          {/*############# DESKTOP ################*/}

          <div className="relative inset-0 hidden mx-auto boxed -top-16 md:max-w-screen-xl 2xl:max-w-screen-2xl md:block">
            <div>
              <SRLWrapper>
                <div className="grid grid-cols-2">
                  {item.galleryThumbnailImage.map((image, index) => {
                    const galleryFullWidhtImage = getSrc(item.galleryFullWidhtImage[index]);
                    const thumbnail = getImage(image);
                    return (
                      <a key={index} href={galleryFullWidhtImage} className="relative gallery-item bg-primary-bg">
                        <h3 className="absolute top-0 left-0 z-20 max-w-lg p-16 pt-8 text-xl font-bold transition-all opacity-0 md:text-2xl font-arboria hover:opacity-50 hover:pt-16">
                          {item.highlights[index]}
                        </h3>
                        <GatsbyImage image={thumbnail} alt={item.highlights[index]} className="w-full h-full transition-all opacity-80 hover:opacity-40" />
                      </a>
                    );
                  })}
                </div>
              </SRLWrapper>
              <div className="grid grid-cols-2 gap-12 pt-32">
                <div>
                  <h2 className="pb-6 text-6xl uppercase font-arboria" data-sal="slide-top" data-sal-easing="ease" data-sal-delay="200" data-sal-duration="500">
                    {item.buzz}
                  </h2>
                  <p className="pt-6" data-sal="slide-top" data-sal-easing="ease" data-sal-delay="300" data-sal-duration="500">
                    {item.description}
                  </p>
                </div>
                <div className="inset-0 w-full p-12 m-auto" data-sal="slide-up" data-sal-delay="400" data-sal-easing="ease" data-sal-duration="500">
                  <YoutubeEmbed embedId={item.youtube} title={item.title}  disabled="true" video={item.video} event={item.event}  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="relative boxed 2xl:max-w-screen-2xl">
          <div className="inset-0 z-20 py-24 mx-auto lg:py-28 md:max-w-screen-xl 2xl:max-w-screen-2xl" id="booking">
            <div className="grid gap-20 md:grid-cols-2">
              <div className="col-span-1" data-sal="slide-left" data-sal-delay="200" data-sal-easing="ease" data-sal-duration="500">
                <h2 className="pb-6 text-3xl font-arboria md:text-6xl">Buchung</h2>
                <h3 className="pt-6 text-3xl md:text-4xl font-arboria">{item.name}</h3>
                <h3 className="text-3xl md:text-4xl font-arboria">
                  Ab <span className="">{item.priceFrom}</span>
                </h3>
                <h3 className="py-3 pt-6 text-lg text-secondary-font">{item.specs}</h3>
                <ul className={`${item.event ? `hidden` : ``} py-6 pb-0 md:pb-6`}>
                  <li className="flex py-2">
                    <img src={Icon1} alt="" className="inline w-8 h-8 mb-2 mr-4 -mt-2" />
                    Verfügbarkeit in Echtzeit
                  </li>
                  <li className="flex py-2">
                    <img src={Icon2} alt="" className="inline w-8 h-8 mr-4" />
                    Abholung und Rückgabe täglich zwischen 8 - 18 Uhr in Walchsee
                  </li>
                  <li className="flex py-2">
                    <img src={Icon3} alt="" className="inline w-8 h-8 mr-4 -mt-1" />
                    Persönliche Einweisung in Ihren Traumwagen
                  </li>
                  <li className="flex py-2">
                    <img src={Icon4} alt="" className="inline w-8 h-8 mr-4 -mt-1" />
                    Wählen Sie zwischen verschiedenen Kilometerpaketen
                    <br />
                    100 Freikilometer inkl.<br />
                    {`${item.electro ? `Strom inkl.` : ``}`}
                  </li>
                  <li className="flex py-2">
                    <img src={Icon5} alt="" className="inline w-8 h-8 mr-4 -mt-1" />
                    Unsere Bezahlmethoden sind Sofortüberweisung, Kreditkarte,
                    <br />
                    Sepa Lastschrift und PayPal
                  </li>
                  <li className="flex py-2">
                    <img src={Icon5} alt="" className="inline w-8 h-8 mr-4 -mt-1" />
                    {item.priceText}
                  </li>
                  <li className="flex py-2">
                    <img src={Icon6} alt="" className="inline w-8 h-8 mr-4 -mt-1" />
                    Mindestalter 25 Jahre, 3 Jahre Führerschein
                  </li>
                  <li className="flex py-2">
                    <img src={Icon7} alt="" className="inline w-8 h-7 mr-4 -mt-1" />
                    Vollkaskoversicherung mit 2.500,- Euro Selbstbehalt
                  </li>

                </ul>
                <ul className={`${item.event ? `` : `hidden`} py-6 pb-0 md:pb-6`}>
                  <li className="flex pb-4">
                    <img src={Event1} alt="" className="inline w-8 h-8 mb-2 mr-4" />
                    Hydraulisches Hubdach mit LED-Beleuchtung, Line-Array System Odin, 2x49" Semi Outdoor Screens, 3G Router und CMS System zur Bespielung der Displays, Infrarotheizung
                  </li>
                  <li className="flex pb-4">
                    <img src={Event2} alt="" className="inline w-8 h-8 mr-4" />
                    Benötigte Standfläche: 6m x 3m, Gewicht: 3.000kg, Stromanschluss: 16A, Aufbauzeit: &#60;1h
                  </li>
                  <li className="flex pb-4">
                    <img src={Icon5} alt="" className="inline w-8 h-8 mr-4" />
                    500,- Euro Event-Pauschale +<br/>
                    100,- Euro pro Stunde +<br/>
                    2,- Euro pro km An/Rückfahrt<br/>
                  </li>
                  <li className="flex pb-4">
                    <img src={Event4} alt="" className="inline w-8 h-8 mr-4" />
                    inkl. Techniker für den Auf- und Abbau<br/>
                    inkl. Techniker für die Betreuung während des Events<br/>
                    Preise inkl. MwSt.<br/>
                  </li>
                </ul>
                <div data-sal="fade" data-sal-delay="600" data-sal-easing="ease" data-sal-duration="1000">
                  <p className="hidden pt-6 font-bold md:block">Haben Sie noch Fragen?</p>
                  <Link to="/agb" className="hidden my-6 md:block w-fit">
                    <button className="block px-6 py-2 mr-auto text-white bg-transparent border border-white rounded hover:bg-secondary-font hover:border-transparent w-80">
                      Zu den Mietbedingungen{' '}
                      <svg x="0" y="0" viewBox="0 0 960 560" class="w-5 fill-current text-white inline -rotate-90">
                        <path d="M480 344.181L268.869 131.889c-15.756-15.859-41.3-15.859-57.054 0-15.754 15.857-15.754 41.57 0 57.431l237.632 238.937c8.395 8.451 19.562 12.254 30.553 11.698 10.993.556 22.159-3.247 30.555-11.698L748.186 189.32c15.756-15.86 15.756-41.571 0-57.431s-41.299-15.859-57.051 0L480 344.181z"></path>
                      </svg>
                    </button>
                  </Link>
                  <Link to="/kontakt" className="hidden my-6 md:block w-fit">
                    <button className="block px-6 py-2 mr-auto text-white bg-transparent border border-white rounded hover:bg-secondary-font hover:border-transparent w-80">
                      Kontaktieren Sie uns persönlich{' '}
                      <svg x="0" y="0" viewBox="0 0 960 560" class="w-5 fill-current text-white inline -rotate-90">
                        <path d="M480 344.181L268.869 131.889c-15.756-15.859-41.3-15.859-57.054 0-15.754 15.857-15.754 41.57 0 57.431l237.632 238.937c8.395 8.451 19.562 12.254 30.553 11.698 10.993.556 22.159-3.247 30.555-11.698L748.186 189.32c15.756-15.86 15.756-41.571 0-57.431s-41.299-15.859-57.051 0L480 344.181z"></path>
                      </svg>
                    </button>
                  </Link>
                </div>
              </div>
              <div className="col-span-1 pt-12 md:pt-0 min-h-[600px] w-fit 2xl:w-full justify-self-center">
                <rentware-article-booking article-id={item.rentwareId} view="calendar" show-fully-booked-days="on"></rentware-article-booking>

                <p className="py-3 pt-12 font-bold md:hidden" data-sal="fade" data-sal-delay="200" data-sal-easing="ease" data-sal-duration="1000">
                  Haben Sie noch Fragen?
                </p>
                <Link to="/agb" className="my-3 md:hidden w-fit">
                  <button
                    data-sal="fade"
                    data-sal-delay="600"
                    data-sal-easing="ease"
                    data-sal-duration="1000"
                    className="block px-6 py-2 my-3 mr-auto text-white bg-transparent border border-white rounded hover:bg-secondary-font hover:border-transparent w-80"
                  >
                    Zu den Mietbedingungen{' '}
                    <svg x="0" y="0" viewBox="0 0 960 560" class="w-5 fill-current text-white inline -rotate-90">
                      <path d="M480 344.181L268.869 131.889c-15.756-15.859-41.3-15.859-57.054 0-15.754 15.857-15.754 41.57 0 57.431l237.632 238.937c8.395 8.451 19.562 12.254 30.553 11.698 10.993.556 22.159-3.247 30.555-11.698L748.186 189.32c15.756-15.86 15.756-41.571 0-57.431s-41.299-15.859-57.051 0L480 344.181z"></path>
                    </svg>
                  </button>
                </Link>
                <Link to="/kontakt" className="md:hidden w-fit">
                  <button
                    data-sal="fade"
                    data-sal-delay="600"
                    data-sal-easing="ease"
                    data-sal-duration="1000"
                    className="block px-6 py-2 my-3 mr-auto text-white bg-transparent border border-white rounded hover:bg-secondary-font hover:border-transparent w-80"
                  >
                    Kontaktieren Sie uns persönlich{' '}
                    <svg x="0" y="0" viewBox="0 0 960 560" class="w-5 fill-current text-white inline -rotate-90">
                      <path d="M480 344.181L268.869 131.889c-15.756-15.859-41.3-15.859-57.054 0-15.754 15.857-15.754 41.57 0 57.431l237.632 238.937c8.395 8.451 19.562 12.254 30.553 11.698 10.993.556 22.159-3.247 30.555-11.698L748.186 189.32c15.756-15.86 15.756-41.571 0-57.431s-41.299-15.859-57.051 0L480 344.181z"></path>
                    </svg>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ItemPageTemplateDe;
